<template>
  <div>
    <div class="list-title">
      <h4 class="title">合同变更详情</h4>
    </div>
    <div class="content page-box">
      <ul class="tabs-wrapper">
        <li v-for="(item, index) in tabs" :class="[activeName === item.value ? 'active' : '']" :key="index" @click="changeTabs(item.value)">
          <template v-if="item.value === '1' || displayBusiness">
            {{item.name}}
          </template>
        </li>
      </ul>
      <section class="content-box" v-if="activeName === '1'">
        <!-- 合同变更信息 -->
        <div class="detail-card">
          <header class="card-header">
            <!-- <h4 class="second-title">合同变更信息</h4> -->
            <TitleBar
              title="合同变更信息"
              :innerBtnBox="true"
              :hasStatus="hasStatus"
            >
              <div slot="btnBox">
                <button
                  class="btn btn-lucency"
                  @click="goMainCon"
                >查看主合同</button>
                <button
                  class="btn btn-lucency"
                  @click="goApproveDetail"
                >
                  审批详情
                </button>
              </div>
            </TitleBar>
          </header>
          <section
            class="card-content"
            v-if="agreementType == 'SIDE_AGREEMENT'"
          >
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">合同变更类型:</label>
                  <p class="text">{{agreeInfo.typeName}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">主合同名称:</label>
                  <p class="text">{{ conInfo.contractName }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">主合同编号:</label>
                  <p class="text">{{ conInfo.contractNo }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">主合同类型:</label>
                  <p class="text">{{ conInfo.contractType }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">合同变更名称:</label>
                  <p class="text">{{agreeInfo.agreementName}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">合同变更编号:</label>
                  <p class="text">{{agreeInfo.agreementNo}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">经办人:</label>
                  <p class="text">{{ agreeInfo.operatorName }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">经办日期:</label>
                  <p class="text">{{agreeInfo.modifyDateStr}}</p>
                </div>
              </el-col>
            </el-row>
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">是否需要付款:</label>
                  <p class="text">{{agreeInfo.needPay == "Y" ? "需要":"不需要"}}</p>
                </div>
              </el-col>
              <!-- 是否供方合同 -->
              <el-col :span="6">
                <div class="item">
                  <label class="label">是否供方合同:</label>
                  <p class="text">
                    {{ agreeInfo.supplyStoreFlag == "0" ? "否" : "是" }}
                  </p>
                </div>
              </el-col>
              <!-- 新增关联项目----new -->
              <el-col :span="6" v-if="agreementType == 'SIDE_AGREEMENT'">
                <div class="item">
                  <label class="label">关联项目:</label>
                  <p class="text">{{agreeInfo.projectName | addSerialTwo}}</p>
                </div>
              </el-col>
              
            </el-row>
          </section>
          <section
            class="card-content"
            v-if="agreementType == 'RELEASE_AGREEMENT'"
          >
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">合同变更类型:</label>
                  <p class="text">{{agreeInfo.typeName}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">主合同名称:</label>
                  <p class="text">{{ conInfo.contractName }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">主合同编号:</label>
                  <p class="text">{{ conInfo.contractNo }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">主合同类型:</label>
                  <p class="text">{{ conInfo.contractType }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">解除协议名称:</label>
                  <p class="text">{{agreeInfo.agreementName}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">解除协议编号:</label>
                  <p class="text">{{agreeInfo.agreementNo}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">经办人:</label>
                  <p class="text">{{agreeInfo.operatorName}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">经办日期:</label>
                  <p class="text">{{agreeInfo.modifyDateStr}}</p>
                </div>
              </el-col>
            </el-row>
          </section>
          <section
            class="card-content"
            v-if="agreementType == 'SUBJECT_CHANGE'"
          >
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">合同变更类型:</label>
                  <p class="text">{{agreeInfo.typeName}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">主合同名称:</label>
                  <p class="text">{{conInfo.contractName}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">主合同编号:</label>
                  <p class="text">{{conInfo.contractNo}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">主合同类型:</label>
                  <p class="text">{{conInfo.contractType}}</p>
                </div>
              </el-col>
            </el-row>
            
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">主体变更协议名称:</label>
                  <p class="text">{{ agreeInfo.agreementName }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">主体变更协议编号:</label>
                  <p class="text">{{ agreeInfo.agreementNo }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">经办人:</label>
                  <p class="text">{{ agreeInfo.operatorName }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">经办日期:</label>
                  <p class="text">{{ agreeInfo.modifyDateStr }}</p>
                </div>
              </el-col>
            </el-row>
            <!-- <el-row
              :gutter="20"
              type="flex"
              align="normal"
              v-if="false"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">原我方签约主体:</label>
                  <p class="text">{{ conInfo.ourCompanyList | ArrayToString }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">现我方签约主体:</label>
                  <p class="text">{{ agreeInfo.ourCompanyList | ArrayToString }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">原对方签约主体:</label>
                  <p class="text">{{ conInfo.supplierCompanyList | ArrayToString }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">现对方签约主体:</label>
                  <p class="text">{{ agreeInfo.supplierCompanyList | ArrayToString }}</p>
                </div>
              </el-col>
            </el-row> -->
            <!-- 是否供方合同 -->
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">是否供方合同:</label>
                  <p class="text">
                    {{ agreeInfo.supplyStoreFlag == "0" ? "否" : "是" }}
                  </p>
                </div>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 补充协议签约主体 -->
        <div
          class="detail-card"
          v-if="agreementType == 'SIDE_AGREEMENT'"
        >
          <header class="card-header">
            <h4 class="second-title">补充协议签约主体</h4>
          </header>
          <div class="body-wrapper">
            <div class="body-cards">
              <h2>我方签约主体</h2>
              <ul>
                <li v-for="(item, index) in agreeInfo.ourCompanyList" :key="index">{{item.name}}</li>
              </ul>
            </div>
            <div class="body-cards">
              <h2>对方签约主体</h2>
              <ul>
                <li v-for="(item, index) in agreeInfo.supplierCompanyList" :key="index">
                  {{item.name}}
                  <!-- <span v-show="item.type === 'T04'">供方</span> -->
                  <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 解除协议签约主体 -->
        <div class="detail-card" v-if="agreementType === 'RELEASE_AGREEMENT'">
          <header class="card-header describe">
            <h4 class="second-title">签约主体</h4>
          </header>
          <div class="body-wrapper">
            <div class="body-cards">
              <h2>我方签约主体</h2>
              <ul>
                <li v-for="(item, index) in agreeInfo.ourCompanyList" :key="index">{{item.name}}</li>
              </ul>
            </div>
            <div class="body-cards">
              <h2>对方签约主体</h2>
              <ul>
                <li v-for="(item, index) in agreeInfo.supplierCompanyList" :key="index">
                  {{item.name}}
                  <!-- <span v-show="item.type === 'T04'">供方</span> -->
                  <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 招采信息 -->
        <div
          class="detail-card"
          v-if="agreementType == 'SIDE_AGREEMENT'"
        >
          <header class="card-header">
            <h4 class="second-title">招采信息</h4>
          </header>
          <section class="card-content">
            <el-row
              :gutter="10"
              type="flex"
              align="normal"
            >
              <el-col :span="24">
                <div class="item">
                  <label class="label">招标/比价/直委:</label>
                  <p class="text">
                    {{ agreeInfo.orderMsg | empty }}
                  </p>
                </div>
              </el-col>
            </el-row>
            <el-row
              :gutter="10"
              type="flex"
              align="normal"
              v-if="orderFile.length !== 0"
            >
              <el-col :span="14">
                <div class="item">
                  <label class="label">附件:</label>
                  <div class="text">
                    <el-table
                      :show-header="false"
                      :data="orderFile"
                    >
                      <el-table-column
                        prop="fileName"
                        min-width="250"
                      >
                        <template slot-scope="scope">
                          <div>
                            <i class="el-icon-document"></i>
                            <a
                              @click="preview(scope.row, conId)"
                              class="linkName"
                            >{{ scope.row.fileName }}</a>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="createDate"
                        min-width="70"
                      >
                        <template slot-scope="scope">
                          <div class="light">
                            <span>{{ scope.row.bizCreateDateStr }}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        align="center"
                        min-width="50"
                      >
                        <template slot-scope="scope">
                          <div class="orderDownload">
                            <span @click="download(scope.row)">下载</span>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 合同变更金额 -->
        <div
          class="detail-card"
          v-if="agreementType == 'SIDE_AGREEMENT' && agreeInfo.needPay == 'Y'"
        >
          <header class="card-header">
            <h4 class="second-title">合同变更金额</h4>
          </header>
          <section class="card-content">
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label" style="width:160px;">
                    <el-tooltip
                      effect="dark"
                      placement="bottom-start"
                      :offset="-10"
                    >
                      <div slot="content">
                        <p>金额追加类：指补充协议的协议金额为在主协议基础上追加的金额</p>
                        <p>金额替代类：指补充协议的协议金额会替代主协议的金额</p>
                        <p>金额不变类：指补充协议无需额外增加金额，仍按主协议的金额付款</p>
                      </div>
                      <img
                        src="../assets/img/information.png"
                        class="money-icon"
                        alt=""
                      />
                    </el-tooltip>
                    补充协议金额类型:
                  </label>
                  <p class="text">{{ { 1: '金额追加类', 2: '金额替代类',3:'金额不变类'}[agreeInfo.agreementAmountType] | empty }}</p>
                </div>
              </el-col>
            </el-row>
            <template v-if="agreeInfo.agreementAmountType != 3">
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">补充协议币种:</label>
                  <p class="text">{{agreeInfo.currency | empty}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label" style="width:160px;">
                    <el-tooltip
                      effect="dark"
                      content="指本次补充协议自身的签约金额"
                      placement="bottom-start"
                      :offset="-10"
                    >
                      <img
                        src="../assets/img/information.png"
                        class="money-icon"
                        alt=""
                      />
                    </el-tooltip>
                    本次补充协议金额(含税):
                  </label>
                  <p class="text">{{ agreeInfo.amount | millimeterFormat }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label" style="width:190px;">
                    <el-tooltip
                      effect="dark"
                      content="指本次补充协议签署后，主协议累计的合同总金额，即合同最大可付款金额"
                      placement="bottom-start"
                      :offset="-10"
                    >
                      <img
                        src="../assets/img/information.png"
                        class="money-icon"
                        alt=""
                      />
                    </el-tooltip>
                    主协议累计总金额(含税):
                  </label>
                  <p class="text">{{ agreeInfo.agreementAfterAmountTax | millimeterFormat }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">发票类型:</label>
                  <p class="text">{{agreeInfo.invoiceTypeName | empty }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row
              :gutter="20"
              type="flex"
              align="normal"
            >
              <el-col :span="6">
                <div class="item">
                  <label class="label">可抵扣税额:</label>
                  <p class="text">{{agreeInfo.deductibleTax | millimeterFormat | empty }}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label">可抵扣税率:</label>
                  <p class="text">{{ agreeInfo.deductibleTaxRate | formatRate}}</p>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="item">
                  <label class="label" style="width:190px;">
                    <el-tooltip
                      effect="dark"
                      content="指本次补充协议签署后，主协议累计的合同总金额（不含抵扣税）"
                      placement="bottom-start"
                      :offset="-10"
                    >
                      <img
                        src="../assets/img/information.png"
                        class="money-icon"
                        alt=""
                      />
                    </el-tooltip>
                    主协议累计总金额(不含抵扣税):
                  </label>
                  <p class="text">
                    {{ agreeInfo.agreementAfterAmountNoTax | millimeterFormat }}
                  </p>
                </div>
              </el-col>
              <el-col :span="6" v-if="agreeInfo.aboveQuotaAmount || agreeInfo.aboveQuotaScale">
                <div class="item">
                  <label class="label">可超额付款比例/金额:</label>
                  <p
                    class="text"
                    v-if="agreeInfo.aboveQuotaType == 'FIX' "
                  >{{ agreeInfo.aboveQuotaAmount | millimeterFormat | empty }}</p>
                  <p
                    class="text"
                    v-else
                  >{{ agreeInfo.aboveQuotaScale | formatRate }}</p>
                </div>
              </el-col>
            </el-row>
            </template>
          </section>
        </div>
        <div class="detail-card" v-if="agreementType == 'SUBJECT_CHANGE'">
          <header class="card-header">
            <h4 class="second-title">签约主体</h4>
          </header>
          <section class="card-content">
            <div class="qy-body-flex-wrapper">
              <!-- 变更前主体 -->
              <div class="qy-body-item gray">
                <h2 class="qy-body-item-title">变更前</h2>
                <ul class="qy-body-item-detail">
                  <li class="qy-body-item-detail-li">
                    <span class="title">我方签约主体：</span>
                    <ul>
                      <li v-if="!(conInfo.ourCompanyList && conInfo.ourCompanyList.length)">-</li>
                      <li v-for="(item, index) in conInfo.ourCompanyList" :key="index">{{item.name}}</li>
                    </ul>
                  </li>
                  <li class="qy-body-item-detail-li">
                    <span class="title">对方签约主体：</span>
                    <ul>
                      <li v-if="!( conInfo.supplierCompanyList &&  conInfo.supplierCompanyList.length)">-</li>
                      <li v-for="(item, index) in  conInfo.supplierCompanyList" :key="index">
                        {{item.name}}
                        <!-- <span v-show="item.type === 'T04'">供方</span>   -->
                        <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!-- 变更后主体 -->
              <div class="qy-body-item pink">
                <h2 class="qy-body-item-title">变更后</h2>
                <ul class="qy-body-item-detail">
                  <li class="qy-body-item-detail-li">
                    <span class="title">我方签约主体：</span>
                    <ul>
                      <li v-if="!(agreeInfo.ourCompanyList && agreeInfo.ourCompanyList.length)">-</li>
                      <li v-for="(item, index) in agreeInfo.ourCompanyList" :key="index">{{item.name}}</li>
                    </ul>
                  </li>
                  <li class="qy-body-item-detail-li">
                    <span class="title">对方签约主体：</span>
                    <ul>
                      <li v-if="!(agreeInfo.supplierCompanyList && agreeInfo.supplierCompanyList.length)">-</li>
                      <li v-for="(item, index) in agreeInfo.supplierCompanyList" :key="index">
                        {{item.name}}
                        <!-- <span v-show="item.type === 'T04'">供方</span>   -->
                        <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
        <!-- 说明 -->
        <div class="detail-card">
          <header class="card-header">
            <h4
              class="second-title"
              v-if="agreementType == 'SIDE_AGREEMENT'"
            >合同变更说明</h4>
            <h4
              class="second-title"
              v-if="agreementType == 'RELEASE_AGREEMENT'"
            >解除协议说明</h4>
            <h4
              class="second-title"
              v-if="agreementType == 'SUBJECT_CHANGE'"
            >主体变更说明</h4>
          </header>
          <section class="card-content">
            <el-row>
              <el-col :span="24">
                <div class="item">
                  <label class="label">说明信息:</label>
                  <p class="text">
                    {{ agreeInfo.agreementNote }}
                  </p>
                </div>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 费项分摊 -->
        <div
          class="detail-card"
          v-if="agreementType == 'SIDE_AGREEMENT' && agreeInfo.needPay === 'Y'"
        >
          <header class="card-header">
            <h4 class="second-title">费项分摊</h4>
          </header>
          <section class="card-content">
            <el-row>
              <el-table
                :data="costDate"
                ref="costDate"
                style="width: 100%"
                border
                fit
                :header-row-style="{ height: '36px' }"
              >
                <el-table-column
                  v-for="(item, index) in costColumn"
                  :key="index"
                  :label="item.label"
                  :prop="item.prop"
                  :align="item.align"
                ></el-table-column>
                <el-table-column
                  label="分摊金额(含税)"
                  prop="amount"
                  align="right"
                >
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.amount | millimeterFormat }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </section>
        </div>
        <!-- 附件 -->
        <div class="detail-card fileCard">
          <header class="card-header file-header">
            <h4 class="second-title">附件</h4>
            <button
              class="btn btn-lucency"
              @click="downloadAll"
              v-if="fileDate.length !== 0"
            >
              下载全部附件
            </button>
          </header>
          <section class="card-content">
            <el-row>
              <el-table
                :data="fileDate"
                ref="fileDate"
                style="width: 100%"
                border
                fit
                :header-row-style="{ height: '36px' }"
              >
                <el-table-column
                  label="附件"
                  min-width="300"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    <i class="el-icon-document"></i>
                      <a
                        @click="preview(scope.row, conId)"
                        class="linkName"
                      >{{ scope.row.fileName }}</a>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  v-for="(item, index) in fileColumn"
                  :key="index"
                  :label="item.label"
                  :prop="item.prop"
                  :align="item.align"
                  :min-width="item.minWidth"
                >
                </el-table-column> -->
                <el-table-column
                  v-for="(item, index) in fileColumn"
                  :key="index"
                  :label="item.label"
                  :align="item.align"
                  :min-width="item.minWidth"
                >
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="top" v-if="scope.row.fileNote && item.prop === 'fileNote' && scope.row.fileNote.length >= 66">
                    <p>{{ scope.row[item.prop] }}</p>
                    <div slot="reference" class="name-wrapper">
                      {{ scope.row[item.prop].slice(0, 66) }}{{ '...' }}
                    </div>
                  </el-popover>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  align="left"
                  min-width="60"
                >
                  <template slot-scope="scope">
                    <div class="fileDownload">
                      <span @click="download(scope.row)"> 下载 </span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </section>
        </div>
      </section>
      <div v-else>
        <iframe class="business-info-wrapper" :src="iframeUrl" frameborder="0"></iframe>
      </div>
      <ImageViewer @closeViewer="closeViewer" v-if="showImageViewer" :urls="imageUrl"></ImageViewer>
    </div>
  </div>
</template>
<script>
import TitleBar from "@/components/common/TitleBar.vue"
import preview from './mixins/preview'
import getCurrentUser from './mixins/getCurrentUser'
export default {
  name: "agreeDetial",
  components: {
    TitleBar
  },
  data() {
    return {
      activeName: '1', // tab标签
      tabs: [{
        value: '1',
        name: '合同信息'
      }, {
        value: '2',
        name: '业务信息'
      }],
      id: "", // 合同变更ID
      sourceSystem: '',
      conId: "", // 主合同ID
      // 合同变更的类型
      agreementType: "",
      // 合同变更状态
      hasStatus: {
        show: true,
        status: ""
      },
      agreeInfo: {},
      conInfo: {},
      orderFile: [], // 招采附件
      // 费项数据
      costDate: [],
      // 表头列表
      costColumn: [
        {
          label: "责任人",
          prop: "operatorName"
        },
        {
          label: "费项",
          prop: "costItemsName"
        },
        {
          label: "预算分摊部门",
          prop: "costItemsDeptName"
        }
      ],
      // 附件列表
      fileDate: [],
      // 附件表头
      fileColumn: [
        {
          label: "备注",
          prop: "fileNote",
          minWidth: 500
        },
        {
          label: "上传时间",
          prop: "bizCreateDateStr",
          minWidth: 180
        }
      ],
      // 全局loading
      loading: null,
      sourceId: '',
      iframeUrl: '',
      displayBusiness: true,
      showIframe: true
    };
  },
  mixins: [preview, getCurrentUser],
  methods: {
    changeTabs(val) {
      this.activeName = val
    },
    // 获取合同变更ID
    getUrl() {
      return new Promise(resolve => {
        let id = this.$route.params.id;
        this.id = id;
        resolve(id)
      })
    },
    // 获取合同变更信息
    getAgreeInfo(id) {
      let params = { id }
      this.$request
        .post("/contractAgreement/getAgreementInfo", { params })
        .then(res => {
          if (res.code === "200") {
            let data = res.data
            this.hasStatus.status = data.auditStatus
            this.agreeInfo = data
            this.conInfo = data.contract
            this.agreementType = data.type
            this.conId = data.contractId
            this.fileDate = data.attachFileList
            this.costDate = data.costExpenseList
            this.displayBusiness = data.displayBusiness || false
            if (this.displayBusiness) {
              this.iframeUrl = data.businessUrl + '&CASTGC=' + this.$store.state.CASTGC
              this.showIframe = true
            }
            // 记录审批详情地址
            this.approvalDetailUrl = data.viewUrl
            this.sourceSystem = data.sourceSystem
            this.sourceId = data.sourceId

            let fileList = data.attachFileList;
            this.orderFile = fileList.filter(item => {
              return item.fileCategory == "RECRUIT";
            })

            this.fileDate = fileList.filter(item => {
              return item.fileCategory == "CONTRACT";
            })
          }
          this.loading.close()
        }).catch((error) =>{
          // 关闭全局loading
          if(this.loading) {
            this.loading.close()
          }
          this.$message.error(error.message)
        })
    },

    // 查看主合同
    goMainCon() {
      this.$open({
        name: "conDetail",
        path: `/condetail/${this.conId}`,
        params: {
          id: this.conId
        }
      });
    },
    // 查看审批结果
    goApproveDetail() {
      if (this.sourceSystem && (this.sourceSystem.includes('COST') || this.sourceSystem.includes('BILLS') || this.sourceSystem.includes('NEW_BILLS') || this.sourceSystem.includes('SHTZ_BILLS'))){
        const query = {
          "sourceSystem": this.sourceSystem,
          "dataType": 'AGREEMENT',
          "sourceContractId": this.sourceId,
          'id': this.$route.params.id,
          "routerPath": this.$route.name,
          "contractId": this.$route.params.id
        }
        this.$router.push({
          path: '/bpmHistory',
          query
        })
      } else {
        this.approvalDetailUrl ? window.open(this.approvalDetailUrl) : window.open(`/nobpm.html`)
      }
    },
    // 下载附件
    download(row) {
      this.$request
        .post("/file/fileDownload", { data: [{ fileId: row.docLibId, "contractId": this.conId }] })
        .then(res => {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.style.height = 0;
          iframe.src = res.data; 
          document.body.appendChild(iframe);
          setTimeout(()=>{
            iframe.remove();
          }, 5 * 60 * 1000);
        });
    },
    // 下载所有附件
    downloadAll() {
      this.fileDate.forEach(item => {
        this.$request
          .post("/file/fileDownload", { data: [{ fileId: item.docLibId, "contractId": this.conId }] })
          .then(res => {
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.style.height = 0;
            iframe.src = res.data; 
            document.body.appendChild(iframe);
            setTimeout(()=>{
              iframe.remove();
            }, 5 * 60 * 1000);
          });
      });
    }
  },
  created() {
    // 打开全局loading
    this.loading = this.$loading({ 
      fullscreen: true,
      background: "rgba(255, 255, 255, 0.8)",
      text: "正在加载数据..."
    })
    this.getUrl().then(res => {
      this.getAgreeInfo(res);
    });
  }
};
</script>

<style lang="less" scoped>
.list-title {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  border-bottom: 1px solid #eaeaea;
  background: #fff;
  margin-bottom: 12px;
}
.page-box {
  .tabs-wrapper{
    display: flex;
    width: 100%;
    height: 47px;
    line-height: 47px;
    border-bottom: 1px solid #EAEAEA;
    padding-left: 19px;
    box-sizing: border-box;
    li{
      font-weight: 600px;
      color: #5B5B5B;
      font-size: 16px;
      margin-right: 26px;
      cursor: pointer;
      position: relative;
      &.active{
        color: #997236;
        &::before{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #997236;
        }
      }
    }
  }
  .business-info-wrapper{
    width: 100%;
    height: calc(100vh - 170px);
    border: none;
  }
  .detail-card {
    .money-icon{
      // vertical-align: text-bottom;
      vertical-align: text-top;
      cursor: pointer;
    }
    .card-header {
      line-height: 36px;
    }
    .card-content {
      .el-row {
        margin-top: 12px;
      }
      .link-name {
        color: #997236;
        margin-left: 12px;

        &:hover {
          cursor: pointer;
        }
      }
    }
    .orderDownload {
      border-left: 1px solid #e5e5e5;
      span {
        color: #997236;

        &:hover {
          cursor: pointer;
        }
      }
    }
    .body-wrapper{
      width: 100%;
      display: flex;
      justify-content: space-between;
      div.body-cards{
        width: 49%;
        border: 2px solid #F0D8A8;
        border-radius: 6px;
        overflow: hidden;
        h2{
          width: 100%;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #323232;
          background: #FBF3E5;
          padding: 6px 18px;
          box-sizing: border-box;
        }
        ul{
          widows: 100%;
          padding: 0 18px;
          padding-top: 12px;
          box-sizing: border-box;
          li{
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #323232;
            margin-bottom: 12px;
            span{
              padding: 2px;
              display: inline-block;
              margin-left: 12px;
              font-weight: 400;
              font-size: 10px !important;
              line-height: 18px;
              color: #7AA2F4;
              background: #EEF3FD;
              border-radius: 2px;
              // width: 32px;
              height: 18px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-tooltip__popper{
  max-width: 100% !important;
  width: 300px;
}
.el-popover{
  background: rgba(50,50,50,0.95);
  color: #fff;
  max-width: 95%;
}
.popper__arrow::after{
  border-top-color: rgba(50,50,50,0.95) !important;
}
</style>